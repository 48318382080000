import { updateState } from "config/utils"
import { AppThunk, RootState } from ".."
import { locationComplaintsService } from "services/locations/locationComplaints.service"
import { LocationComplaintInterface } from "@type/locations.types"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ComplaintState } from "@config/constants"

export interface locationComplaintsInterface {
	list: LocationComplaintInterface[]
	loading: boolean
	saving: boolean
}

const initialState: locationComplaintsInterface = {
	list: [],
	loading: false,
	saving: false,
}

const locationComplaintsSlice = createSlice({
	name: "locationComplaints",
	initialState,
	reducers: {
		fetchingComplaints: complaints => {
			complaints.loading = true
		},
		savingComplaints: complaints => {
			complaints.saving = true
		},
		fetchedComplaints: (complaints, action: PayloadAction<LocationComplaintInterface[]>) => {
			complaints.loading = false
			complaints.list = action.payload
		},
		savedComplaints: (complaints, action: PayloadAction<LocationComplaintInterface>) => {
			complaints.saving = false
			const [updatedState] = updateState(complaints.list, action.payload, "_id")
			complaints.list = updatedState
		},
		fetchingComplaintsFailed: complaints => {
			complaints.loading = false
		},
		savingComplaintsFailed: complaints => {
			complaints.saving = false
		},
		clearlocationComplaints: complaints => {
			complaints.list = []
		},
	},
})

export default locationComplaintsSlice.reducer

export const {
	fetchingComplaints,
	savingComplaints,
	fetchedComplaints,
	savedComplaints,
	fetchingComplaintsFailed,
	savingComplaintsFailed,
	clearlocationComplaints,
} = locationComplaintsSlice.actions

const saveComplaint =
	(payload: any, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingComplaints())
			const { _id, ...rest } = payload
			const { data: response } = _id
				? await locationComplaintsService.updateLocationComplaint(_id, rest)
				: await locationComplaintsService.saveLocationComplaint(rest)
			dispatch(savedComplaints(response))
			cb && cb()
		} catch (error) {
			dispatch(savingComplaintsFailed())
		}
	}

const resolveLocationComplaint =
	(complaintId: string, payload: { status: ComplaintState }, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingComplaints())
			const { data: response } = await locationComplaintsService.resolveLocationComplaint(complaintId, payload)
			dispatch(savedComplaints(response))
			cb && cb()
		} catch (error) {
			dispatch(savingComplaintsFailed())
		}
	}

const getLocationComplaints =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingComplaints())
			const { data: response } = await locationComplaintsService.getLocationComplaints(locationId)
			dispatch(fetchedComplaints(response))
		} catch (error) {
			dispatch(fetchingComplaintsFailed())
		}
	}

export { getLocationComplaints, saveComplaint, resolveLocationComplaint }

const selectlocationComplaintsState = (state: RootState) => state.location.complaints
const islocationComplaintsLoading = () => (state: RootState) => selectlocationComplaintsState(state).loading
const islocationComplaintsSaving = () => (state: RootState) => selectlocationComplaintsState(state).saving
const locationComplaintsList = () => (state: RootState) => selectlocationComplaintsState(state).list

export {
	islocationComplaintsLoading,
	islocationComplaintsSaving,
	locationComplaintsList,
	selectlocationComplaintsState,
}
