// PACKAGES/ HOOKS
import React, { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector, useFeatureFlags, useHeightResize } from "hooks"
import { AllowedTo, useAbac } from "react-abac"

// COMPONENTS
import LocationDetails from "./Details/LocationDetails"
import Contracts from "./Contracts/Contract"
import { LocationSidebar, AntdButton } from "components"

import AdditionalDetail from "./AdditionalDetail/AdditionalDetail"
import Escalations from "./Escalations/Escalation"
import LocationTopBar from "./LocationsTopBar"
import Documents from "./Documents/Documents"
import Posts from "./Posts/Posts"

import { getCustomers } from "store/Customers/customers.slice"
import TimeCaptures from "./TimeCapture/TimeCaptures"
import { getPayGroup } from "store/PayGroups/payGroup.slice"
import LocationQualification from "./Qualifications/LocationQualifications"
import { getBranches } from "store/Branches/branch.slice"
import { LOCATION_SOCKET_EVENTS, ROOM_EVENTS, SOCKET_ROOMS } from "types/webSocket.types"
import { socket } from "config/socket"
import { locationAddingEventClicked, locationSaved } from "store/Location/locationDetails.slice"
import { getSDCounties } from "store/StaticData/SDCounties.slice"
import { Permissions } from "config/constants"
import QRCodeTab from "./QRCode/QRCodeTab"
import { selectSelectedLocation, setSelectedLocation } from "store/globalState.slice"
import Incidents from "./Incidents/Incidents"
import { Divider, Segmented } from "antd-v5"
import { FeatureFlagEnums } from "types/featureFlags.type"
import Complaints from "./Complaints/Complaints"
// UTILS/CONSTANTS

const Index: React.FC = () => {
	useHeightResize({ element: "location-segment-body" })
	const dispatch = useAppDispatch()
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [value, setValue] = useState<string>("Detail")

	const selectedLocation = useAppSelector(selectSelectedLocation)

	const handleSelected = (location: string) => dispatch(setSelectedLocation(location))

	useEffect(() => {
		dispatch(getCustomers())
		dispatch(getPayGroup())
		dispatch(getBranches(false))
		dispatch(getSDCounties())
	}, [])

	useEffect((): any => {
		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.LOCATIONS)
		socket.on(LOCATION_SOCKET_EVENTS.ADDED, (res: any) => dispatch(locationSaved({ ...res, shouldSelect: false })))
		socket.on(LOCATION_SOCKET_EVENTS.UPDATED, res => dispatch(locationSaved({ ...res, shouldSelect: false })))

		return () => socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.LOCATIONS)
	}, [])
	const options = useMemo(() => {
		return [
			{
				label: "Detail",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_DETAILS,
				component: <LocationDetails />,
			},
			{
				label: "Escalations",
				perform: Permissions.VIEW_LOCATION_ESCALATION,
				featureName: FeatureFlagEnums.LOCATIONS_ESCALATIONS,
				component: <Escalations />,
			},
			{
				label: "Contracts",
				perform: Permissions.VIEW_LOCATION_CONTRACTS,
				featureName: FeatureFlagEnums.LOCATIONS_CONTRACTS,
				component: <Contracts />,
			},
			{
				label: "Posts",
				perform: Permissions.VIEW_LOCATION_POSTS,
				featureName: FeatureFlagEnums.LOCATIONS_POSTS,
				component: <Posts />,
			},
			{
				label: "Qualifications",
				perform: Permissions.VIEW_LOCATION_QUALIFICATIONS,
				featureName: FeatureFlagEnums.LOCATIONS_QUALIFICATIONS,
				component: <LocationQualification />,
			},
			{
				label: "Time Captures",
				perform: Permissions.VIEW_LOCATION_TIME_CAPTURE,
				featureName: FeatureFlagEnums.LOCATIONS_TIME_CAPTUTRES,
				component: <TimeCaptures />,
			},
			{
				label: "Additional Detail",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_ADDITIONAL_DETAILS,
				component: <AdditionalDetail />,
			},
			{
				label: "Documents",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_DOCUMENTS,
				component: <Documents />,
			},
			{
				label: "QR Code",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_QR_CODE,
				component: <QRCodeTab />,
			},
			{
				label: "Incidents",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_INCIDENTS,
				component: <Incidents />,
			},
			{
				label: "Complaints",
				perform: Permissions.VIEW_LOCATION_DETAILS,
				featureName: FeatureFlagEnums.LOCATIONS_COMPLAINTS, // TODO: needs to be updated
				component: <Complaints />,
			},
		]
			.filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
			.map(item => ({ ...item, value: item.label }))
	}, [selectedLocation])

	return (
		<>
			<LocationSidebar selected={selectedLocation} handleSelected={handleSelected} />
			<div className="sticky top-0 z-10">
				<AllowedTo perform={Permissions.VIEW_LOCATION_DETAILS}>
					<LocationTopBar />
				</AllowedTo>
				<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
				<div className="flex items-center justify-between pr-5">
					<Segmented
						disabled={!selectedLocation}
						options={options as any}
						value={value}
						onChange={(e: any) => setValue(e)}
					/>
					<AntdButton
						onClick={() => {
							dispatch(locationAddingEventClicked(true))
							dispatch(setSelectedLocation(""))
							setValue("Detail")
						}}
					>
						Add Site
					</AntdButton>
				</div>
				<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			</div>
			<div id="location-segment-body" className="overflow-y-auto px-2">
				{options?.find(item => item.value === value)?.component}
			</div>
		</>
	)
}

export default Index
