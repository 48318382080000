import { combineReducers } from "@reduxjs/toolkit"
import locationDetailsReducer from "./locationDetails.slice"
import locationQualificationReducer from "./locationQualification.slice"
import locationAdditionalDetailReducer from "./AdditionalDetail/additionalDetail.slice"
import locationEquipmentReducer from "./locationEquipment.slice"
import documentReducer from "./Documents/documents.slice"
import locationEscalationsSlice from "./locationEscalations.slice"
import locationTimeCaptureSlice from "./locationTimeCapture.slice"
import locationContractsSlice from "./locationContracts.slice"
import locationPostsSlice from "./locationPosts.slice"
import locationCallTimeSlice from "./locationCallTime.slice"
import locationInventoryCallSlice from "./locationInventoryCall.slice"
import locationQRCallSlice from "./locationQRCall.slice"
import locationIncidentsSlice from "./locationIncidents.slice"
import locationComplaintsSlice from "./locationComplaints.slice"

const LocationReducer = combineReducers({
	details: locationDetailsReducer,
	additionalDetail: locationAdditionalDetailReducer,
	escalations: locationEscalationsSlice,
	contracts: locationContractsSlice,
	timeCapture: locationTimeCaptureSlice,
	posts: locationPostsSlice,
	locationDocument: documentReducer,
	locationEquipment: locationEquipmentReducer,
	qualifications: locationQualificationReducer,
	callTime: locationCallTimeSlice,
	inventoryCall: locationInventoryCallSlice,
	qrCall: locationQRCallSlice,
	incidents: locationIncidentsSlice,
	complaints: locationComplaintsSlice,
})

export default LocationReducer
