import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Modal, NearbyEmployeesModal, AntdButton, FormInputAntd, AntdSelect1 } from "components"
import {
	AllocateScheduleInterface,
	FilterEmployeeFromSupplierOrBranchInterface,
	GetScheduleFromTableInterface,
} from "@type/schedules.types"
import { AllocationSchedulesByLocationValidationSchema } from "Schema"
import { useContext, useEffect, useMemo, useState } from "react"
import useAppSelector from "hooks/useAppSelector"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { getSDSuppliers, selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { filterEmployeeByBranchOrSupplierName } from "store/Employee/detail.slice"
import EmployeeRetainContext, {
	EmployeeRetainContextProps,
	CreateScheduleRetainSource,
} from "./CreateScheduleRetainContext"
import { selectScheduleEmployeeAllocation, allocatingSchedule } from "store/Schedules/schedules.slice"
import { getEmployeesNearbyLocation } from "store/Geolocation/geolocation.slice"
import { selectSelectedLocation } from "store/globalState.slice"
import { selectLocationById } from "store/Location/locationDetails.slice"
import { Switch } from "antd"
import { AllowedTo } from "react-abac"
import { EmployeeTypesEnum, Permissions } from "config/constants"
import { GetPostsInterface } from "@type/locations.types"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	setShowEmployeeModal: (open: boolean) => void
	infoModalHandler: (open: boolean) => void
	postData: GetPostsInterface | null
	showInfo: { [key: string]: string }
	selectedSchedules: (GetScheduleFromTableInterface | null)[]
	resetTable: () => void
	resetSelection: (reset: () => void) => void
}

const AllocateScheduleModal: React.FC<Props> = ({
	open,
	modalHandler,
	showInfo,
	postData,
	infoModalHandler,
	selectedSchedules = [],
	setShowEmployeeModal,
	resetTable = () => undefined,
	resetSelection,
}) => {
	const methods = useForm<AllocateScheduleInterface>({
		resolver: yupResolver(AllocationSchedulesByLocationValidationSchema),
		shouldUnregister: true,
	})

	const { handleSubmit, setValue, reset, watch, getValues } = methods

	const dispatch = useAppDispatch()
	//Selectors
	const branchList = useAppSelector(selectBranchesMappedAsOptions())
	const supplierList = useAppSelector(selectSDSuppliersDropdownList)
	const isEmployeeAllocation = useAppSelector(selectScheduleEmployeeAllocation())
	const selected = useAppSelector(selectSelectedLocation)
	const location = useAppSelector(selectLocationById(selected))

	// Context
	const { createScheduleModalData, handleCreateScheduleModalData } = useContext(
		EmployeeRetainContext,
	) as EmployeeRetainContextProps
	const [isModalOpen, setIsModalOpen] = useState(false)

	//States
	const [filterOptions, setFilterOptions] = useState<FilterEmployeeFromSupplierOrBranchInterface>({
		filterFrom: postData?.type ? "type" : "",
		filterId: postData?.type ? postData?.type : "",
	})

	const filterEmployeeListByBranchOrSupplierName = useAppSelector(
		filterEmployeeByBranchOrSupplierName(filterOptions.filterId, filterOptions.filterFrom),
	)
	useEffect(() => {
		if (createScheduleModalData !== null) {
			reset(createScheduleModalData)
			setValue("employee", createScheduleModalData?.employee || "", { shouldDirty: true })
			setValue("blowout", createScheduleModalData.blowout || false, { shouldDirty: true })
			setValue("bypassSiaVerification", createScheduleModalData.bypassSiaVerification)
			setValue(
				"bypassMaxConsecutiveWorkdaysVerification",
				createScheduleModalData.bypassMaxConsecutiveWorkdaysVerification,
			)
		} else {
			setValue("employee", "")
			setValue("bypassSiaVerification", false)
			setValue("bypassMaxConsecutiveWorkdaysVerification", false)
		}
	}, [createScheduleModalData])

	useEffect(() => {
		dispatch(getSDSuppliers(false))
	}, [])

	const onSubmit = (data: AllocateScheduleInterface) => {
		const { employee, blowout, notes, bypassSiaVerification, bypassMaxConsecutiveWorkdaysVerification } = data
		const payload = {
			employee,
			...(blowout ? { blowout } : {}),
			ids: selectedSchedules.filter(schedule => schedule !== null).map(schedule => schedule?._id || ""),
			bypassSiaVerification,
			bypassMaxConsecutiveWorkdaysVerification,
			notes,
		}
		dispatch(allocatingSchedule(payload, () => resetTable()))
	}

	const handleResetFilterOption = () => {
		setValue("branch", "")
		setValue("supplier", "")
		setFilterOptions({ filterFrom: postData?.type ? "type" : "", filterId: postData?.type ? postData?.type : "" })
	}

	const handleViewEmployeeDetails = () => {
		setShowEmployeeModal(true)
		handleCreateScheduleModalData({ source: CreateScheduleRetainSource.ALLOCATE, ...getValues() })
	}
	const handleButton = () => {
		resetSelection(handleResetFilterOption)
		infoModalHandler(true)
	}

	const searchNearbyPostTime = useMemo(() => {
		if (!postData || !postData?.type) return EmployeeTypesEnum.SECURITY
		else if (postData?.type === EmployeeTypesEnum.SECURITY) return EmployeeTypesEnum.SECURITY
		else return EmployeeTypesEnum.CLEANING
	}, [postData])

	useEffect(() => {
		setFilterOptions({ filterFrom: postData?.type ? "type" : "", filterId: postData?.type ? postData?.type : "" })
	}, [postData])

	return (
		<>
			<Modal
				containerClass="min-w-[550px]"
				open={open}
				title={
					<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">
						Allocate Schedules
					</div>
				}
				modalHandler={modalHandler}
			>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)} className="w-full">
						<div className="flex items-center justify-between py-2">
							<div className="flex flex-col text-sm font-semibold text-dark">
								<span>Site: {showInfo.location}</span>
								<span> Posts: {postData?.name || ""}</span>
							</div>
							<div className={`flex items-center justify-end space-x-2`}>
								<AntdButton htmlType="button" onClick={handleButton}>
									Best Fit
								</AntdButton>
								<AntdButton
									onClick={e => {
										e.preventDefault()
										setIsModalOpen(true)
										dispatch(getEmployeesNearbyLocation(location?._id || "", searchNearbyPostTime))
									}}
									htmlType="button"
								>
									Nearby
								</AntdButton>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-x-3 gap-y-2">
							<AntdSelect1
								placeholder="Select Branch"
								onChange={value => {
									setValue("branch", value)
									setValue("supplier", "")
									setFilterOptions({ filterId: value, filterFrom: "branch" })
								}}
								value={watch("branch")}
								label="Branch"
								options={[{ label: "Select Branch", value: "" }, ...branchList]?.map(
									({ label, value }) => ({ label, value }),
								)}
							/>
							<AntdSelect1
								placeholder="Select Supplier"
								onChange={value => {
									setValue("branch", "")
									setValue("supplier", value)
									setFilterOptions({ filterId: value, filterFrom: "supplier" })
								}}
								value={watch("supplier")}
								label="Supplier"
								options={supplierList?.map(({ label, value }) => ({ label, value }))}
							/>

							<div>
								<AntdSelect1
									label="Employee"
									value={watch("employee")}
									onChange={value => {
										if (value) {
											setValue("employee", value)
											handleCreateScheduleModalData({
												...getValues(),
												employee: value,
											})
										}
									}}
									options={[
										{ label: "Select Employee", value: "" },
										...filterEmployeeListByBranchOrSupplierName,
									]?.map(({ label, value }) => ({ label, value }))}
								/>
								<div
									className={`h-4 translate-y-1/2 cursor-pointer text-sm  text-dark hover:underline ${
										watch("employee") || !!createScheduleModalData?.employee ? "visible" : "hidden"
									}`}
									onClick={handleViewEmployeeDetails}
								>
									View Details
								</div>
							</div>
							<div className="flex flex-col items-end justify-center space-y-3">
								<div className="flex space-x-3">
									<label>Blowout</label>
									<Switch
										disabled={!selectedSchedules.some(sch => sch?.employee)}
										checked={watch("blowout") || createScheduleModalData?.blowout}
										onChange={checked => setValue("blowout", checked)}
									/>
								</div>
								<AllowedTo perform={Permissions.BYPASS_SIA_VALIDATION}>
									<div
										className="flex cursor-pointer space-x-3"
										onClick={() =>
											setValue("bypassSiaVerification", !watch("bypassSiaVerification"))
										}
									>
										<label className="text-md font-normal">Bypass SIA verification</label>
										<Switch checked={watch("bypassSiaVerification")} />
									</div>
								</AllowedTo>

								<AllowedTo perform={Permissions.BYPASS_MAX_CONSECUTIVE_WORKDAYS_VALIDATION}>
									<div
										className="flex cursor-pointer space-x-3"
										onClick={() =>
											setValue(
												"bypassMaxConsecutiveWorkdaysVerification",
												!watch("bypassMaxConsecutiveWorkdaysVerification"),
											)
										}
									>
										<label className="text-md font-normal">
											Bypass Max Consecutive Workdays verification
										</label>
										<Switch checked={watch("bypassMaxConsecutiveWorkdaysVerification")} />
									</div>
								</AllowedTo>
							</div>
							<div className="col-span-2">
								<FormInputAntd type="textarea" label="Notes" name="notes" />
							</div>
						</div>
						<AntdButton loading={isEmployeeAllocation} className="mt-5" htmlType="submit">
							Save
						</AntdButton>
					</form>
				</FormProvider>
			</Modal>
			<NearbyEmployeesModal
				filterEmployeeType={postData?.type || ""}
				modalHandler={(close: boolean) => setIsModalOpen(close)}
				open={isModalOpen}
				location={location}
				reset={handleResetFilterOption}
				canSelect={true}
			/>
		</>
	)
}

export default AllocateScheduleModal
