import React, { useEffect, useMemo, useRef } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"

import { AntdButton, AntdSelect1, DateField, FormCheckbox, FormInputAntd, FormSelectAntd } from "components"
import FormSkeleton from "components/FormSkeleton"

import {
	getLocationContractsByLocationId,
	isLocationContractsLoading,
	isLocationContractsSaving,
	saveLocationContracts,
	selectLocationContractsData,
} from "store/Location/locationContracts.slice"

import { LocationSchema } from "Schema"
import { GetLocationContractsInterface } from "@type/locations.types"

import { convertIOSDateToHTMLFormat } from "config/utils"
import { getPBCodes, selectPBCodeDropdownList } from "store/PayBill/PBCodes.slice"
import { Ongoing, PBCodeOptions, Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedLocation } from "store/globalState.slice"

const { contractValidationSchema, contractInitialValues } = LocationSchema

const Contract: React.FC = () => {
	const dispatch = useAppDispatch()

	const selected = useAppSelector(selectSelectedLocation)

	const contractLoading = useAppSelector(isLocationContractsLoading())
	const contracts = useAppSelector(selectLocationContractsData())
	const saving = useAppSelector(isLocationContractsSaving())
	const PBCodesList = useAppSelector(selectPBCodeDropdownList)
	const shouldSelectOngoingContract = useRef(true)

	const methods = useForm<GetLocationContractsInterface>({ resolver: yupResolver(contractValidationSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isDirty, isSubmitting, errors },
	} = methods

	const contract = useMemo(() => {
		if (shouldSelectOngoingContract.current && contracts.length) {
			shouldSelectOngoingContract.current = false
			return contracts.find(item => !item.finishDate)
		}
		return contracts.find(({ _id }) => _id === watch("_id"))
	}, [watch("_id"), contracts])

	useEffect(() => {
		dispatch(getPBCodes(PBCodeOptions.CONTRACT))
	}, [])

	useEffect(() => {
		if (selected) {
			shouldSelectOngoingContract.current = true
			dispatch(getLocationContractsByLocationId(selected))
		}
	}, [selected])

	useEffect(() => {
		if (contract?._id) reset({ ...contractInitialValues, ...contract })
		else reset({ ...contractInitialValues })
	}, [contract])

	const onSubmit: SubmitHandler<GetLocationContractsInterface> = data =>
		dispatch(saveLocationContracts({ ...data, location: selected }))

	if (contractLoading) return <FormSkeleton />

	return (
		<div className="my-2 flex flex-col">
			<div className="mt-2 ml-2">
				<AuditTrail id={contract?._id} name={Entities.LOCATION_CONTRACTS} />
			</div>

			<div className="mt-4 flex flex-col">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="space-y-4">
							<div className="grid grid-cols-4 items-center gap-y-2 gap-x-3">
								<div className="col-span-4 w-1/4">
									<AntdSelect1
										label="Contracts"
										onChange={value => {
											if (value === "") reset({ ...contractInitialValues })
											else setValue("_id", value)
										}}
										value={watch("_id")}
										options={[
											{ label: "Create Contract", value: "" },
											...contracts.map(({ startDate, finishDate, _id }) => ({
												label: finishDate
													? `${convertIOSDateToHTMLFormat(
															startDate,
													  )}-${convertIOSDateToHTMLFormat(finishDate)}`
													: `${convertIOSDateToHTMLFormat(startDate)}- ${Ongoing}`,
												value: _id,
											})),
										]}
									/>
								</div>
								<FormInputAntd type="text" label="Reference" name="reference" />
								<FormSelectAntd
									disabled={!PBCodesList.length}
									label="PB Code"
									name="PBCode"
									options={PBCodesList}
								/>
								<DateField
									label="Start Date"
									name={"startDate"}
									setValue={setValue}
									watch={watch}
									errors={errors}
								/>
								<DateField
									label="Finish Date"
									name={"finishDate"}
									setValue={setValue}
									watch={watch}
									errors={errors}
								/>
								<FormInputAntd type="number" min={0} label="Hours Per Week" name="hoursPerWeek" />
								<FormInputAntd type="number" min={0} label="Weeks In Roster" name="weeksInRoster" />
								<FormInputAntd type="number" min={0} label="VAT Rating" name="vatRating" />
								<FormInputAntd type="text" label="Service Type" name="serviceType" />
								<FormInputAntd type="textarea" label="Service Description" name="serviceDescription" />
								<FormInputAntd type="text" label="Payment Terms" name="paymentTerms" />
								<FormInputAntd
									type="text"
									label="Max Consecutive Workdays for Employee"
									name="maxConsecWorkdaysForEmployee"
								/>
								<FormCheckbox label="Auto Generate Enabled" name="autoGenerateEnabled" />
							</div>
							<AllowedTo perform={Permissions.UPDATE_LOCATION_CONTRACTS}>
								<div className="flex gap-3">
									<AntdButton loading={saving} disabled={!isDirty || isSubmitting} htmlType="submit">
										{contract ? "Update" : "Save"}
									</AntdButton>
									<AntdButton
										onClick={e => {
											e.preventDefault()
											reset(contractInitialValues)
										}}
										htmlType="submit"
									>
										Clear
									</AntdButton>
								</div>
							</AllowedTo>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	)
}

export default Contract
