import moment from "moment"
import { Table } from "antd-v5"
import AntdButton from "../../../components/AntdButton"
import { Tab, Tabs } from "../../../components/Tabs"
import ComplaintModal from "./ComplaintModal"
import useAppDispatch from "../../../hooks/useAppDispatch"
import useAppSelector from "../../../hooks/useAppSelector"

import { useEffect, useState } from "react"
import { selectSelectedLocation } from "../../../store/globalState.slice"
import ResolveLocationComplaintModal from "./ResolveLocationComplaintModal"
import { LocationComplaintInterface } from "../../../types/locations.types"
import { selectSelectedLocationData } from "../../../store/Location/locationDetails.slice"
import { ComplaintState, DATE_FORMATS } from "../../../config/constants"
import { PlusOutlined } from "@ant-design/icons"
import { caseConverters } from "../../../config/utils"
import { getLocationComplaints, locationComplaintsList } from "../../../store/Location/locationComplaints.slice"
// import { generateLocationComplaintPDF } from "../../../config/pdf"

const Complaints: React.FC = () => {
	const [openModal, setOpenModal] = useState(false)
	const [openResolveModal, setOpenResolveModal] = useState(false)
	const [isOpenResolveModalAsReadOnly, setIsOpenResolveModalAsReadOnly] = useState(false)
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)
	const complaintList = useAppSelector(locationComplaintsList())
	const [selectedComplaint, setSelectedComplaint] = useState<LocationComplaintInterface | null>(null)
	const selectedLocation = useAppSelector(selectSelectedLocationData)
	// const { fetchComplaintCommentsByComplaint, isLoading } = useComplaintComments()

	useEffect(() => {
		dispatch(getLocationComplaints(selected))
	}, [selected])

	// The following commented-out code pertains to report generation functionality. It is not currently needed but will be integrated in a future phase of development.

	// const handleDownloadReport = async (row: LocationComplaintInterface) => {
	// 	if (row._id) {
	// 		const data = await fetchComplaintCommentsByComplaint(row._id)
	// 		generateLocationComplaintPDF(row, data || [])
	// 	}
	// }

	return (
		<>
			<Tabs initialTab={0}>
				<Tab title="Site Complaints" hasHeader shouldOverflow={false}>
					<Table
						size="small"
						pagination={{
							position: ["bottomRight"],
							defaultPageSize: 12,
							pageSize: 12,
						}}
						dataSource={complaintList}
						columns={[
							{
								title: "Time Of Event",
								key: "date",
								dataIndex: "date",
								render: value => <div> {moment(value).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>,
							},
							{
								title: "Complaint",
								key: "complaint",
								dataIndex: "complaint",
							},
							{
								title: "Status",
								key: "status",
								dataIndex: "status",
								render: value => <div> {caseConverters.titleCase(value)}</div>,
							},

							{
								title: "Action",
								key: "",
								dataIndex: "",
								render: (_, row) => (
									<>
										<div className="flex flex-row gap-x-2">
											<AntdButton
												onClick={() => {
													setSelectedComplaint(selectedRow =>
														selectedRow === row ? null : row,
													)
													setOpenModal(true)
												}}
												disabled={row.status === ComplaintState.RESOLVED}
											>
												Update
											</AntdButton>

											<AntdButton
												disabled={row.status === ComplaintState.RESOLVED}
												onClick={() => {
													setSelectedComplaint(row)
													setOpenResolveModal(true)
												}}
											>
												Resolve
											</AntdButton>
										</div>
									</>
								),
							},

							{
								title: "",
								key: "",
								dataIndex: "",
								render: (_, row) => (
									<>
										<div className="flex flex-row gap-x-2">
											<AntdButton
												onClick={() => {
													setSelectedComplaint(selectedRow =>
														selectedRow === row ? null : row,
													)
													setOpenResolveModal(true)
													setIsOpenResolveModalAsReadOnly(true)
												}}
												type="text"
												className="text-primary"
											>
												View Details
											</AntdButton>
										</div>
									</>
								),
							},

							// The following commented-out code pertains to report generation functionality. It is not currently needed but will be integrated in a future phase of development.

							// {
							// 	title: "Report",
							// 	key: "",
							// 	render: (_, row) => (
							// 		<AntdButton
							// 			className="flex items-center justify-center"
							// 			icon={<CloudDownloadOutlined />}
							// 			onClick={() => handleDownloadReport(row)}
							// 			loading={isLoading}
							// 			disabled={isLoading}
							// 		>
							// 			Download
							// 		</AntdButton>
							// 	),
							// },
						]}
					/>
					<div className="flex justify-end pt-4">
						<AntdButton icon={<PlusOutlined />} onClick={() => setOpenModal(val => !val)}>
							Add New
						</AntdButton>
					</div>
				</Tab>
			</Tabs>

			<ComplaintModal
				selectedLocation={selectedLocation?._id ?? ""}
				open={openModal}
				modalHandler={(open: boolean) => {
					setOpenModal(open)
					setSelectedComplaint(null)
				}}
				selectedRow={null}
				defaultFields={
					selectedComplaint
						? {
								...selectedComplaint,
								officer:
									typeof selectedComplaint.officer === "object"
										? selectedComplaint.officer?._id ?? ""
										: selectedComplaint.officer ?? "",
						  }
						: undefined
				}
			/>

			<ResolveLocationComplaintModal
				modalHandler={close => {
					setOpenResolveModal(close)
					setSelectedComplaint(null)
					setIsOpenResolveModalAsReadOnly(close)
				}}
				open={openResolveModal}
				selectedComplaint={selectedComplaint}
				readonly={isOpenResolveModalAsReadOnly}
			/>
		</>
	)
}

export default Complaints
