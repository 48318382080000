import { ScheduleTypesEnum } from "config/constants"
import { createContext } from "react"

export enum CreateScheduleRetainSource {
	VIEW_EMPLOYEE_DETAILS = "VIEW_EMPLOYEE_DETAILS",
	// CREATE = "CREATE",
	ALLOCATE = "ALLOCATE",
	RECURRING = "RECURRING",
}
export interface CreateScheduleRetainInterface {
	location?: string
	post?: string
	startTime?: string
	finishTime?: string
	date?: string
	employee?: string
	user?: string
	createMultiple?: string
	checkMultiple?: boolean
	notes?: string
	branch?: string
	supplier?: string
	type?: ScheduleTypesEnum
	source?: CreateScheduleRetainSource | []
	payload?: string[]
	blowout?: boolean
	bypassSiaVerification?: boolean
	bypassMaxConsecutiveWorkdaysVerification?: boolean
}

export type CreateScheduleRetain = CreateScheduleRetainInterface | null

export interface EmployeeRetainContextProps {
	createScheduleModalData: CreateScheduleRetain
	handleCreateScheduleModalData: (data: CreateScheduleRetain) => void
}

const EmployeeRetainContext = createContext<EmployeeRetainContextProps>({
	createScheduleModalData: null,
	handleCreateScheduleModalData: () => undefined,
})

export default EmployeeRetainContext
