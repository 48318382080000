import * as Yup from "yup"

export const contactDetailsInitialValues = {
	name: "",
	position: "",
	contactMethod: "",
	phoneOrEmail: "",
	isPrimaryContact: false,
	isActive: false,
}

export const customerInitialValues = {
	name: "",
	telephone: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postalCode: "",
	reference1: "",
	reference2: "",
	active: false,
	requiredQualifications: [],
	contactDetail: contactDetailsInitialValues,
	enableConsecutiveWorkDaysCheck: false,
}

export const customerSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	organization: Yup.string().required().label("Organization"),
	telephone: Yup.string().label("Telephone"),
	addressLine1: Yup.string().label("Address Line1"),
	addressLine2: Yup.string().label("Address Line2"),
	city: Yup.string().label("City"),
	county: Yup.string().label("County"),
	postalCode: Yup.string().label("Postal Code"),
	reference1: Yup.string().label("Reference 1"),
	reference2: Yup.string().label("Reference 2"),
	requiredQualifications: Yup.array(Yup.string()).label("Required Qualifications"),
	active: Yup.boolean().label("Active"),
	contactDetail: Yup.lazy(value => {
		if (value && Object.values(value).every(v => !(v === null || v === undefined || v === ""))) {
			return Yup.object({
				name: Yup.string().required().label("Name"),
				position: Yup.string().required().label("Position"),
				contactMethod: Yup.string().required().label("Contact Method"),
				phoneOrEmail: Yup.string().required().label("Phone or Email"),
				isPrimaryContact: Yup.boolean().required().label("Is Primary Contact"),
				isActive: Yup.boolean().required().label("Is Active"),
			}).notRequired()
		}
		return Yup.mixed().notRequired()
	}),
	enableConsecutiveWorkDaysCheck: Yup.boolean().label("Enable Consecutive Workdays Check"),
})
