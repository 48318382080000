import * as Yup from "yup"
import { VALIDATION_REGEX } from "../../config/constants"

Yup.addMethod(Yup.object, "atLeastOneOf", function (list) {
	return this.test({
		name: "atLeastOneOf",
		message: "${path} must have at least one of these keys: ${keys}",
		exclusive: true,
		params: { keys: list.join(", ") },
		test: value => {
			return value == null || list?.some((f: string) => value[f] != null)
		},
	})
})

const cliBehaviourRadioLabels = [
	{
		title: "Reject",
		value: "Reject",
	},
	{
		title: "Accept, but flag",
		value: "Accept, but flag",
	},
	{
		title: "Accept",
		value: "Accept",
	},
]
const alarmRadioLabels = [
	{
		title: "",
		value: "Reject",
	},
	{
		title: "",
		value: "",
	},
	{
		title: "",
		value: "Accept",
	},
]
const daysLabels = [
	{
		title: "Mon",
		value: "Mon",
	},
	{
		title: "Tue",
		value: "Tue",
	},
	{
		title: "Wed",
		value: "Wed",
	},
	{
		title: "Thu",
		value: "Thu",
	},
	{
		title: "Fri",
		value: "Fri",
	},
	{
		title: "Sat",
		value: "Sat",
	},
	{
		title: "Sun",
		value: "Sun",
	},
	{
		title: "All",
		value: "All",
	},
	{
		title: "PH",
		value: "PH",
	},
]
const contactsSchema = {
	name: "",
	position: "",
	method: "",
	telephone: "",
	email: "",
	isPrimary: false,
}

const contractInitialValues = {
	_id: "",
	PBCode: "",
	reference: "",
	startDate: "",
	finishDate: "",
	hoursPerWeek: 10,
	weeksInRoster: 10,
	autoGenerateEnabled: true,
	vatRating: 10,
	serviceType: "",
	serviceDescription: "",
	paymentTerms: "",
	maxConsecWorkdaysForEmployee: null,
}
const detailsInitialValues = {
	branch: "",
	customer: "",
	name: "",
	shortName: "",
	telephone: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	image: null,
	postalCode: "",
	contacts: [],
}
const qualificationsInitialValues = {
	contract: "",
	post: "",
}
const additionalDetailsInitialValues = {
	details: "",
}
const escalationInitialValues = {
	name: "",
	details: "",
	reviewed: "",
	reviewedBy: "",
	controlComments: "",
	suppress3rdPartyIncidents: true,
	checkedByControl: true,
	description: "",
	windowStartTime: "",
	windowFinishTime: "",
	applicableDays: [],
}

const documentsInitialValues = {
	title: "",
	description: "",
	document: "",
}
const timeCapturesInitialValues = {
	employeeCreateEnabled: false,
	autoAllocateEnabled: false,
	overdueOffCallVisible: false,
	outboundTelephone: "",
	onCall: {
		before: "",
		after: "",
		overdueAction: "",
	},
	offCall: {
		before: 0,
		after: "",
		overdueAction: "",
	},
	checkCall: {
		before: "",
		after: "",
		overdueAction: "",
	},
	inventoryCall: {
		before: "",
		after: "",
		overdueAction: "",
	},
	qrCall: {
		before: "",
		after: "",
		overdueAction: "Take",
	},
	minimumImages: 5,
}

const LocationAdditionalDetailInitialValues = {
	location: "",
	key: "",
	pouch: "",
	description: "",
	position: "",
	serialNo: "",
	keyType: "",
	issueDate: "",
	returnDate: "",
	checked: "",
	nextCheck: "",
	notes: "",
}

const LocationEquipmentInitialValues = {
	location: "",
	equipment: "",
	quantity: 0,
	serialNo: "",
	issueDate: "",
	returnDate: "",
	checked: "",
	nextCheck: "",
	notes: "",
}

export const locationComplaintModalInitialValues = {
	date: "",
	images: [],
	details: "",
	location: "",
	complaint: "",
	complainant: "",
	officer: "",
	reportingOfficer: "",
}

export const complaintCommentsInitialValues = {
	comment: "",
}

const timeCaptureValidationSchema = Yup.object().shape({
	contract: Yup.string().required().label("Contract"),
	post: Yup.string().required().label("Post"),
	// employeeCreateEnabled: Yup.boolean().required().label("Employee Create Enabled"),
	// autoAllocateEnabled: Yup.boolean().required().label("Auto Allocate Enabled"),
	// overdueOffCallVisible: Yup.boolean().required().label("Over Due Off Call Visible"),
	// outboundTelephone: Yup.string().label("Outbound Telephone"),
	onCall: Yup.object().shape({
		before: Yup.number().required().label("Before").typeError("must be a number"),
		after: Yup.number().required().label("After").typeError("must be a number"),
		// overdueAction: Yup.string().label("Overdue Action"),
	}),
	offCall: Yup.object().shape({
		before: Yup.number().default(0).nullable().label("Before").typeError("must be a number"),
		after: Yup.number().required().label("After").typeError("must be a number"),
		// overdueAction: Yup.string().label("Overdue Action"),
	}),
	// checkCall: Yup.object().shape({
	// 	before: Yup.number().required().label("Before").typeError("must be a number"),
	// 	after: Yup.number().required().label("After").typeError("must be a number"),
	// 	overdueAction: Yup.string().label("Overdue Action"),
	// 	multiTake: Yup.boolean().required().label("Multi Take"),
	// }),

	// inventoryCall: Yup.object().shape({
	// 	before: Yup.number().required().label("Before").typeError("must be a number"),
	// 	after: Yup.number().required().label("After").typeError("must be a number"),
	// 	overdueAction: Yup.string().label("Overdue Action"),
	// }),
	// qrCall: Yup.object().shape({
	// 	before: Yup.number().required().label("Before").typeError("must be a number"),
	// 	after: Yup.number().required().label("After").typeError("must be a number"),
	// 	overdueAction: Yup.string().label("Overdue Action"),
	// }),
	// minimumImages: Yup.number().label("Minimum Images").min(5).required().typeError("must be a number"),
})
const documentsValidationSchema = Yup.object().shape({
	title: Yup.string().required().label("Title"),
	description: Yup.string().required().label("Description"),
	document: Yup.string().required().label("Document"),
})

const escalationValidationSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	details: Yup.string().required().label("Details"),
	reviewed: Yup.string().required().label("Reviewed"),
	reviewedBy: Yup.string().required().label("Reviewed By"),
	controlComments: Yup.string().required().label("Control Comments"),
	suppress3rdPartyIncidents: Yup.boolean().required().label("Suppress 3rd Party Incidents"),
	checkedByControl: Yup.boolean().required().label("control Comments"),
	description: Yup.string().required().label("Description"),
	windowStartTime: Yup.string().required().label("Window Start Time"),
	windowFinishTime: Yup.string().required().label("window Finish Time"),
	applicableDays: Yup.array().of(Yup.string()).required().nullable().label("applicableDays"),
})
const additionalDetailsValidationSchema = Yup.object().shape({
	details: Yup.string().required().label("Details"),
})
const qualificationsValidationSchema = Yup.object().shape({
	contract: Yup.string().required().label("Contract"),
	post: Yup.string().required().label("Post"),
})

const contractValidationSchema = Yup.object().shape({
	_id: Yup.string().label("Contract"),
	PBCode: Yup.string().optional().nullable().label("Contract PB Code"),
	reference: Yup.string().optional().label("reference Code"),
	startDate: Yup.string().required().label("Start Date"),
	finishDate: Yup.string().optional().nullable().label("Finish Date"),
	hoursPerWeek: Yup.number()
		.optional()
		.label("Hours Per Week")
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable(),
	weeksInRoster: Yup.number()
		.optional()
		.label("Weeks in Roster")
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable(),
	autoGenerateEnabled: Yup.boolean().optional().label("Auto Generated Enable"),
	vatRating: Yup.number()
		.optional()
		.label("VAT Rating")
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable(),
	serviceType: Yup.string().optional().label("Service Type"),
	serviceDescription: Yup.string().optional().label("Service Description"),
	paymentTerms: Yup.string().optional().label("Payment Terms"),
	maxConsecWorkdaysForEmployee: Yup.mixed()
		.transform(value => (value === null || value === "" ? null : Number(value)))
		.test(
			"maxConsecWorkdaysForEmployee",
			"Must be a number greater than zero",
			value => value === null || (typeof value === "number" && value > 0),
		)
		.nullable()
		.label("Max Consecutive Workdays for Employee"),
})

const detailsValidationSchema = Yup.object().shape({
	branch: Yup.string().required().label("Branch"),
	customer: Yup.string().required().label("Customer"),
	name: Yup.string().required().label("Name"),
	shortName: Yup.string().required().label("Short Name"),
	telephone: Yup.string()
		.label("Telephone must be a number")
		.required()
		.label("Telephone")
		.matches(VALIDATION_REGEX.phone, "Invalid Phone Number"),
	addressLine1: Yup.string().required().label("Address Line 1"),
	addressLine2: Yup.string().required().label("Address Line 2"),
	city: Yup.string().required().label("City"),
	county: Yup.string().required().label("County"),
	postalCode: Yup.string().required().label("Post Code").matches(VALIDATION_REGEX.postalCode, "Invalid Postal Code"),
	contacts: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required().label("Contact Name"),
			email: Yup.string().email().label("Contact Email"),
			method: Yup.string().required().label("Contact Method"),
		}),
	),
})

const addLocationAdditionalDetailSchema = Yup.object().shape({
	key: Yup.string().required().label("Key"),
	pouch: Yup.string().label("Pouch"),
	description: Yup.string().label("Description"),
	position: Yup.string().label("Position"),
	serialNo: Yup.string().label("Serial Number"),
	keyType: Yup.string().label("Key Type"),
	issueDate: Yup.string().label("Issued Date"),
	returnDate: Yup.string().label("Return Date"),
	checked: Yup.string().label("Checked"),
	nextCheck: Yup.string().label("Next Check"),
	notes: Yup.string().label("Notes"),
})

const addLocationEquipmentSchema = Yup.object().shape({
	equipment: Yup.string().required().label("Key"),
	quantity: Yup.number().label("Quantity"),
	serialNo: Yup.string().label("Serial Number"),
	issueDate: Yup.string().label("Issue Date"),
	returnDate: Yup.string().label("Return Date"),
	checked: Yup.string().label("Checked"),
	nextCheck: Yup.string().label("Next Check"),
	notes: Yup.string().label("Notes"),
})

const LocationCallTimeSchema = Yup.object()
	.shape({
		post: Yup.string().label("post"),
		time: Yup.string()
			.transform(value => (value === "" ? undefined : value))
			.matches(VALIDATION_REGEX.TIME_HH_MM, "Invalid time")
			.nullable()
			.label("time"),
		hours: Yup.number()
			.min(0)
			.max(12)
			.transform(value => (isNaN(value) ? undefined : value))
			.nullable()
			.label("hours"),
		minutes: Yup.number()
			.min(0)
			.max(60)
			.transform(value => (isNaN(value) ? undefined : value))
			.nullable()
			.label("minutes"),
	})
	.test({
		name: "atLeastOneOf",
		message: "Not Working",
		exclusive: true,
		params: { keys: ["time", " hours", " minutes"] },
		test: function (value) {
			let check: boolean | Yup.ValidationError = false
			if (value["time"]) {
				if (value["hours"] || value["minutes"]) {
					check = this.createError({ message: "Enter Time or Hours and Minutes", path: "hours" })
				} else {
					check = true
				}
			} else if (value["hours"] && value["minutes"]) {
				check = true
			} else if (value["hours"]) {
				check = true
				// check = this.createError({ message: "Enter Minutes", path: "minutes" })
			} else if (value["minutes"]) {
				check = true
				// check = this.createError({ message: "Enter Hours", path: "hours" })
			} else {
				check = this.createError({ message: "Enter Time or Hours and Minutes", path: "time" })
			}

			return check
		},
	})

const LocationInventoryCallSchema = Yup.object()
	.shape({
		post: Yup.string().label("post"),
		time: Yup.string()
			.transform(value => (value === "" ? undefined : value))
			.matches(VALIDATION_REGEX.TIME_HH_MM, "Invalid time")
			.nullable()
			.label("time"),
		hours: Yup.number()
			.min(0)
			.max(12)
			.transform(value => (isNaN(value) ? undefined : value))
			.nullable()
			.label("hours"),
		minutes: Yup.number()
			.min(0)
			.max(60)
			.transform(value => (isNaN(value) ? undefined : value))
			.nullable()
			.label("minutes"),
	})
	.test({
		name: "atLeastOneOf",
		message: "Not Working",
		exclusive: true,
		params: { keys: ["time", " hours", " minutes"] },
		test: function (value) {
			let check: boolean | Yup.ValidationError = false
			if (value["time"]) {
				if (value["hours"] || value["minutes"]) {
					check = this.createError({ message: "Enter Time or Hours and Minutes", path: "hours" })
				} else {
					check = true
				}
			} else if (value["hours"] && value["minutes"]) {
				check = true
			} else if (value["hours"]) {
				check = true
				// check = this.createError({ message: "Enter Minutes", path: "minutes" })
			} else if (value["minutes"]) {
				check = true
				// check = this.createError({ message: "Enter Hours", path: "hours" })
			} else {
				check = this.createError({ message: "Enter Time or Hours and Minutes", path: "time" })
			}

			return check
		},
	})

const LocationSimpleTimeSchema = Yup.object()
	.shape({
		checkCall: Yup.string().label("Check Call"),
		inventoryCall: Yup.string().label("Inventory Call"),
		qrCall: Yup.string().label("QR Scan"),
	})
	.test({
		name: "atLeastOneOf",
		message: "Not Working",
		exclusive: true,
		params: { keys: ["checkCall", "inventoryCall", "qrCall"] },
		test: function (value) {
			if (!value["checkCall"] && !value["inventoryCall"] && !value["qrCall"]) {
				return this.createError({ message: "Choose at least one option", path: "parent" })
			}
			return true
		},
	})
const locationIncidentSchema = Yup.object().shape({
	incident: Yup.string().required().label("Incident"),
	type: Yup.string().required().label("Type"),
	date: Yup.string().required().label("Date and Time of Incident"),
	policeRequested: Yup.boolean().required().label("Police Requested"),
	reference: Yup.string().when("policeRequested", { is: true, then: Yup.string().required().label("Reference") }),
	actionTaken: Yup.boolean().required().label("Action Taken"),
	controlInformed: Yup.boolean().required().label("Control Informed"),
	details: Yup.string().required().label("Details of Incident"),
	reportingOfficer: Yup.string().required().label("Reporting Officer Name"),
	images: Yup.array().label("images"),
})

const locationComplaintSchema = Yup.object().shape({
	complaint: Yup.string().required().label("Complaint"),
	details: Yup.string().required().label("Details of Complaint"),
	date: Yup.string().required().label("Date and Time of Complaint"),
	reportingOfficer: Yup.string().required().label("Reporting Officer Name"),
	images: Yup.array().label("images"),
	complainant: Yup.string().label("Complainant"),
	officer: Yup.string().nullable().label("Officer"),
})

export const complaintCommentsSchema = Yup.object().shape({
	comment: Yup.string().required().label("Comment"),
})

export {
	LocationCallTimeSchema,
	addLocationEquipmentSchema,
	LocationEquipmentInitialValues,
	LocationAdditionalDetailInitialValues,
	addLocationAdditionalDetailSchema,
	detailsInitialValues,
	contactsSchema,
	detailsValidationSchema,
	contractInitialValues,
	contractValidationSchema,
	additionalDetailsInitialValues,
	additionalDetailsValidationSchema,
	qualificationsInitialValues,
	qualificationsValidationSchema,
	escalationInitialValues,
	escalationValidationSchema,
	documentsInitialValues,
	documentsValidationSchema,
	cliBehaviourRadioLabels,
	daysLabels,
	alarmRadioLabels,
	timeCapturesInitialValues,
	timeCaptureValidationSchema,
	LocationInventoryCallSchema,
	LocationSimpleTimeSchema,
	locationIncidentSchema,
	locationComplaintSchema,
}
