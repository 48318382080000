import { useState } from "react"
import ComplaintCommentsService from "../services/complaintComments/ComplaintComments.service"
import { ComplaintCommentInterface, CreateComplaintCommentDto } from "../types/locations.types"

const useComplaintComments = () => {
	const [complaintComments, setComplaintComments] = useState<ComplaintCommentInterface[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const fetchComplaintCommentsByComplaint = async (complaint: string) => {
		try {
			setIsLoading(true)
			const { data: complaintCommentsResponse } = await ComplaintCommentsService.getAllByComplaintId(complaint)
			setComplaintComments(complaintCommentsResponse)
			setIsLoading(false)
			return complaintCommentsResponse
		} catch {
			setIsLoading(false)
		}
	}

	const createComplaintComment = async (data: CreateComplaintCommentDto, cb?: any) => {
		try {
			setIsSaving(true)
			const { data: complaintCommentsResponse } = await ComplaintCommentsService.create(data)
			setComplaintComments([...complaintComments, complaintCommentsResponse])
			cb?.()
			setIsSaving(false)
			return complaintCommentsResponse
		} catch {
			setIsSaving(false)
		}
	}

	return {
		isSaving,
		isLoading,
		complaintComments,
		createComplaintComment,
		fetchComplaintCommentsByComplaint,
	}
}

export default useComplaintComments
