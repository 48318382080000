export enum FeatureFlagEnums {
	SCHEDULES = "SCHEDULES",
	SCHEDULES_BY_LOCATION = "SCHEDULES_BY_LOCATION",
	SCHEDULES_BY_EMPLOYEE = "SCHEDULES_BY_EMPLOYEE",
	SCHEDULES_BY_FILTER = "SCHEDULES_BY_FILTER",
	EMPLOYEES = "EMPLOYEES",
	EMPLOYEES_DETAILS = "EMPLOYEES_DETAILS",
	EMPLOYEES_SERVICE = "EMPLOYEES_SERVICE",
	EMPLOYEES_HR = "EMPLOYEES_HR",
	EMPLOYEES_HOLIDAYS = "EMPLOYEES_HOLIDAYS",
	EMPLOYEES_SICKNESS = "EMPLOYEES_SICKNESS",
	EMPLOYEES_TERMS = "EMPLOYEES_TERMS",
	EMPLOYEES_LOCATION = "EMPLOYEES_LOCATION",
	EMPLOYEES_EQUIPMENTS = "EMPLOYEES_EQUIPMENTS",
	EMPLOYEES_DOCUMENTS = "EMPLOYEES_DOCUMENTS",
	EMPLOYEES_ADDITIONAL_DETAILS = "EMPLOYEES_ADDITIONAL_DETAILS",
	EMPLOYEES_QUALIFICATIONS = "EMPLOYEES_QUALIFICATIONS",
	LOCATIONS = "LOCATIONS",
	LOCATIONS_DETAILS = "LOCATIONS_DETAILS",
	LOCATIONS_ESCALATIONS = "LOCATIONS_ESCALATIONS",
	LOCATIONS_CONTRACTS = "LOCATIONS_CONTRACTS",
	LOCATIONS_POSTS = "LOCATIONS_POSTS",
	LOCATIONS_QUALIFICATIONS = "LOCATIONS_QUALIFICATIONS",
	LOCATIONS_TIME_CAPTUTRES = "LOCATIONS_TIME_CAPTUTRES",
	LOCATIONS_ADDITIONAL_DETAILS = "LOCATIONS_ADDITIONAL_DETAILS",
	LOCATIONS_DOCUMENTS = "LOCATIONS_DOCUMENTS",
	LOCATIONS_QR_CODE = "LOCATIONS_QR_CODE",
	LOCATIONS_INCIDENTS = "LOCATIONS_INCIDENTS",
	LOCATIONS_COMPLAINTS = "LOCATIONS_COMPLAINTS",
	COMMAND_CENTER = "COMMAND_CENTER",
	JOB_APPLICATION_FORM = "JOB_APPLICATION_FORM",
	ACCOUNTS = "ACCOUNTS",
	ACCOUNTS_PAYROLL = "ACCOUNTS_PAYROLL",
	ACCOUNTS_MASTER_SHEET = "ACCOUNTS_MASTER_SHEET",
	ANALYTICS = "ANALYTICS",
	ADMIN = "ADMIN",
	ADMIN_SECURITY = "ADMIN_SECURITY",
	ADMIN_SECURITY_USERS = "ADMIN_SECURITY_USERS",
	ADMIN_PAYBILL = "ADMIN_PAYBILL",
	ADMIN_PAYBILL_PB_RULES = "ADMIN_PAYBILL_PB_RULES",
	ADMIN_PAYBILL_PUBLIC_HOLIDAYS = "ADMIN_PAYBILL_PUBLIC_HOLIDAYS",
	ADMIN_PAYBILL_PB_CODES = "ADMIN_PAYBILL_PB_CODES",
	ADMIN_PAYBILL_TERMS = "ADMIN_PAYBILL_TERMS",
	ADMIN_STATIC_DATA = "ADMIN_STATIC_DATA",
	ADMIN_STATIC_DATA_BRANCHES = "ADMIN_STATIC_DATA_BRANCHES",
	ADMIN_STATIC_DATA_CUSTOMERS = "ADMIN_STATIC_DATA_CUSTOMERS",
	ADMIN_STATIC_DATA_SUPPLIERS = "ADMIN_STATIC_DATA_SUPPLIERS",
	ADMIN_STATIC_DATA_COMPANIES = "ADMIN_STATIC_DATA_COMPANIES",
	ADMIN_STATIC_DATA_QUALIFICATIONS = "ADMIN_STATIC_DATA_QUALIFICATIONS",
	ADMIN_STATIC_DATA_EQUIPMENT_TYPES = "ADMIN_STATIC_DATA_EQUIPMENT_TYPES",
	ADMIN_STATIC_DATA_COUNTIES = "ADMIN_STATIC_DATA_COUNTIES",
	ADMIN_STATIC_DATA_INCIDENTS = "ADMIN_STATIC_DATA_INCIDENTS",
	ADMIN_STATIC_DATA_PAY_GROUPS = "ADMIN_STATIC_DATA_PAY_GROUPS",
	ADMIN_STATIC_DATA_REASONS = "ADMIN_STATIC_DATA_REASONS",
	ADMIN_STATIC_DATA_CT_MESSAGES = "ADMIN_STATIC_DATA_CT_MESSAGES",
	ADMIN_STATIC_DATA_BILL_GROUPS = "ADMIN_STATIC_DATA_BILL_GROUPS",
	ADMIN_STATIC_DATA_HR_LISTS = "ADMIN_STATIC_DATA_HR_LISTS",
	ADMIN_STATIC_DATA_DOCUMENT_TYPES = "ADMIN_STATIC_DATA_DOCUMENT_TYPES",
	ADMIN_STATIC_DATA_NOTIFICATIONS = "ADMIN_STATIC_DATA_NOTIFICATIONS",

	REPORTS = "REPORTS",
	REPORTS_HOURS_SUMMARY_BY_EMPLOYEE = "REPORTS_HOURS_SUMMARY_BY_EMPLOYEE",
	REPORTS_HOURS_SUMMARY_BY_LOCATION = "REPORTS_HOURS_SUMMARY_BY_LOCATION",
	REPORTS_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY = "REPORTS_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY",
	REPORTS_TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY = "REPORTS_TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY",
	REPORTS_HOURS_WORKED_BY_LOCATION_DUTIES_ONLY = "REPORTS_HOURS_WORKED_BY_LOCATION_DUTIES_ONLY",
	REPORTS_ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES = "REPORTS_ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES",
	REPORTS_ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES = "REPORTS_ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES",
	REPORTS_LOCATION_DISTRIBUTION_BY_SHIFTS = "REPORTS_LOCATION_DISTRIBUTION_BY_SHIFTS",
	REPORTS_UNALLOCATED_DUTIES_BY_DATE = "REPORTS_UNALLOCATED_DUTIES_BY_DATE",
	REPORTS_EMPLOYEES_DETAIL = "REPORTS_EMPLOYEES_DETAIL",
	REPORTS_CHECK_CALLS_BY_LOCATION = "REPORTS_CHECK_CALLS_BY_LOCATION",
	REPORTS_QR_CALLS_BY_LOCATION = "REPORTS_QR_CALLS_BY_LOCATION",
	REPORTS_INVENTORY_CALLS_BY_LOCATION = "REPORTS_INVENTORY_CALLS_BY_LOCATION",
	REPORTS_ACTIVITY_REPORT_BY_LOCATION = "REPORTS_ACTIVITY_REPORT_BY_LOCATION",
	REPORTS_SHIFT_INCIDENT_REPORT_BY_LOCATION = "REPORTS_SHIFT_INCIDENT_REPORT_BY_LOCATION",
	REPORTS_LOCATION_INCIDENT_REPORT_BY_LOCATION = "REPORTS_LOCATION_INCIDENT_REPORT_BY_LOCATION",
	REPORTS_EMPLOYEES_BY_SIA_EXPIRY = "REPORTS_EMPLOYEES_BY_SIA_EXPIRY",
	REPORTS_HOURS_PAYCHECK_BY_EMPLOYEE = "REPORTS_HOURS_PAYCHECK_BY_EMPLOYEE",
	REPORTS_LOCATION_COMPLAINT_REPORT_BY_LOCATION = "REPORTS_LOCATION_COMPLAINT_REPORT_BY_LOCATION",
	REPORTS_WEEKLY_SCHEDULED_SHIFTS_BY_SITE = "REPORTS_WEEKLY_SCHEDULED_SHIFTS_BY_SITE",
	SPECIAL_FEATURE = "SPECIAL_FEATURE",
}
