import * as Yup from "yup"

export const SchedulesByLocationValidationSchema = Yup.object().shape(
	{
		startTime: Yup.string().required().label("Start Time"),
		finishTime: Yup.string().required().label("Finish Time "),
		createMultiple: Yup.string().when("createMultiple", {
			is: (value: any) => value?.length,
			then: rule => rule.matches(/^[2-7]$/, "Number should be from 2 - 7."),
		}),
		employee: Yup.string().label("Employee"),
		notes: Yup.string().optional().label("Notes"),
		type: Yup.string().optional().label("Schedule Type"),
		bypassSiaVerification: Yup.boolean().default(false).label("Bypass SIA Verification"),
		bypassMaxConsecutiveWorkdaysVerification: Yup.boolean()
			.default(false)
			.label("Bypass Max Consecutive Workdays Verification"),
	},
	[["createMultiple", "createMultiple"]],
)

export const AllocationSchedulesByLocationValidationSchema = Yup.object().shape({
	employee: Yup.string().label("Please Select Employee"),
	bypassSiaVerification: Yup.boolean().default(false).label("Bypass SIA Verification"),
	bypassMaxConsecutiveWorkdaysVerification: Yup.boolean()
		.default(false)
		.label("Bypass Max Consecutive Workdays Verification"),
})

export const AllocationSchedulesByEmployeeValidationSchema = Yup.object().shape({
	location: Yup.string().required().label("Please Select Location"),
	contract: Yup.string().label("Please Select Contract"),
	post: Yup.string().required().label("Please Select Post"),
	startTime: Yup.string().required().label("Start Time"),
	finishTime: Yup.string().required().label("Finish Time"),
	bypassSiaVerification: Yup.boolean().default(false).label("Bypass SIA Verification"),
	bypassMaxConsecutiveWorkdaysVerification: Yup.boolean()
		.default(false)
		.label("Bypass Max Consecutive Workdays Verification"),
})

export const ScheduleFilterSchema = Yup.object().shape({
	state: Yup.string().required().label("Select Filter"),
	startTime: Yup.string().required().label("Select Start Date"),
	finishTime: Yup.string().required().label("Select Finish Date"),
	maxRows: Yup.number()
		.nullable()
		.transform(value => {
			if (!value || isNaN(value)) return 200
			return value
		})
		.label("Select Max Rows"),
	branch: Yup.string().required(),
})
