import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Modal, AntdSelect1, FormSelectAntd, FormInputAntd, AntdButton } from "components"
import { AllocateScheduleInterface, FilterEmployeeFromSupplierOrBranchInterface } from "@type/schedules.types"
import { AllocationSchedulesByLocationValidationSchema } from "Schema"
import { useEffect, useState } from "react"
import useAppSelector from "hooks/useAppSelector"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { getSDSuppliers, selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { filterEmployeeByBranchOrSupplierName } from "store/Employee/detail.slice"
import { allocatingSchedule, selectScheduleEmployeeAllocation } from "store/Schedules/schedules.slice"
import { TableCell } from "./EmployeeTable"
import { Switch } from "antd"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedSchedules: TableCell[]
	setSelectedCell: (data: []) => void
}

const AllocateEmployeeModal: React.FC<Props> = ({
	open,
	modalHandler,
	selectedSchedules = [],
	setSelectedCell = () => undefined,
}) => {
	const methods = useForm<AllocateScheduleInterface>({
		resolver: yupResolver(AllocationSchedulesByLocationValidationSchema),
		shouldUnregister: true,
		defaultValues: {
			branch: "Select Branch",
			supplier: "Select Supplier",
			employee: "",
			notes: "",
		},
	})

	const { handleSubmit, setValue, reset, watch } = methods

	const dispatch = useAppDispatch()
	//Selectors
	const branchList = useAppSelector(selectBranchesMappedAsOptions())
	const supplierList = useAppSelector(selectSDSuppliersDropdownList)
	const isEmployeeAllocation = useAppSelector(selectScheduleEmployeeAllocation())

	//States
	const [filterOptions, setFilterOptions] = useState<FilterEmployeeFromSupplierOrBranchInterface>({
		filterId: "",
		filterFrom: "",
	})

	const filterEmployeeListByBranchOrSupplierName = useAppSelector(
		filterEmployeeByBranchOrSupplierName(filterOptions.filterId, filterOptions.filterFrom),
	)

	useEffect(() => {
		setValue("employee", "")
	}, [selectedSchedules])

	useEffect(() => {
		dispatch(getSDSuppliers(false))
	}, [])

	const onSubmit = (data: AllocateScheduleInterface) => {
		const { employee, notes } = data

		const payload = {
			employee,
			ids: selectedSchedules.map(cell => cell?._id || ""),
			notes,
		}

		dispatch(
			allocatingSchedule(payload, () => {
				setSelectedCell([])
				modalHandler(false)
			}),
		)
	}

	return (
		<Modal
			containerClass="min-w-[600px]"
			open={open}
			title={
				<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">
					Allocate Schedules
				</div>
			}
			modalHandler={modalHandler}
		>
			<FormProvider {...methods}>
				<form
					onSubmit={handleSubmit(data => {
						onSubmit(data)
						reset({ employee: "" })
						setValue("employee", "")
					})}
				>
					<div className="grid grid-cols-2 gap-2">
						<AntdSelect1
							value={watch("branch")}
							onChange={value => {
								setValue("supplier", "Select Supplier")
								setValue("branch", value)
								setFilterOptions({ filterId: value, filterFrom: "branch" })
							}}
							label="Branch"
							options={[{ label: "Select Branch", value: "Select Branch" }, ...branchList]?.map(
								({ label, value }) => ({ label, value }),
							)}
						/>
						<AntdSelect1
							value={watch("supplier")}
							onChange={value => {
								setValue("branch", "Select Branch")
								setValue("supplier", value)
								setFilterOptions({ filterId: value, filterFrom: "supplier" })
							}}
							label="Supplier"
							options={supplierList?.map(({ label, value }) => ({ label, value }))}
						/>

						<FormSelectAntd
							label="Employee"
							name="employee"
							options={[
								{ label: "Select Employee", value: "" },
								...filterEmployeeListByBranchOrSupplierName,
							]?.map(({ label, value }) => ({ label, value }))}
						/>

						<div className="flex w-full items-center">
							<AllowedTo perform={Permissions.BYPASS_SIA_VALIDATION}>
								<div
									className="cursor-pointer"
									onClick={() => setValue("bypassSiaVerification", !watch("bypassSiaVerification"))}
								>
									<label className="mr-2 cursor-pointer text-md font-normal">
										Bypass SIA verification
									</label>
									<Switch checked={watch("bypassSiaVerification")} />
								</div>
							</AllowedTo>

							<AllowedTo perform={Permissions.BYPASS_MAX_CONSECUTIVE_WORKDAYS_VALIDATION}>
								<div
									className="cursor-pointer"
									onClick={() =>
										setValue(
											"bypassMaxConsecutiveWorkdaysVerification",
											!watch("bypassMaxConsecutiveWorkdaysVerification"),
										)
									}
								>
									<label className="mr-2 cursor-pointer text-md font-normal">
										Bypass Max Consecutive Workdays verification
									</label>
									<Switch checked={watch("bypassMaxConsecutiveWorkdaysVerification")} />
								</div>
							</AllowedTo>
						</div>
						<div className="col-span-2">
							<FormInputAntd type="textarea" label="Notes" name="notes" />
						</div>
					</div>
					<AntdButton loading={isEmployeeAllocation} className="mt-5" htmlType="submit">
						Save
					</AntdButton>
				</form>
			</FormProvider>
		</Modal>
	)
}

export default AllocateEmployeeModal
