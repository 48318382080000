import { ComplaintCommentInterface, CreateComplaintCommentDto } from "@type/locations.types"
import axios from "axios"

const ROOT_PATH = "/complaint-comments"

export default class ComplaintCommentsService {
	static getAllByComplaintId = (complaintId: string) =>
		axios.get<ComplaintCommentInterface[]>(`${ROOT_PATH}/complaint/${complaintId}`)

	static create = (body: CreateComplaintCommentDto) => axios.post(ROOT_PATH, body)
}
