import Icon from "../../../components/Icon"
import Modal from "../../../components/Modal"
import Loading from "../../../components/Loading"
import AntdButton from "../../../components/AntdButton"
import FormInputAntd from "../../../components/FormInputAntd"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { yupResolver } from "@hookform/resolvers/yup"
import useComplaintComments from "../../../hooks/useComplaintComments"
import { Statistic, Timeline } from "antd-v5"
import { FormProvider, useForm } from "react-hook-form"
import { FC, useEffect, useMemo } from "react"
import { ComplaintState, DATE_FORMATS } from "../../../config/constants"
import { caseConverters, getMomentZInstanceOfDate } from "../../../config/utils"
import { CreateComplaintCommentDto, LocationComplaintInterface } from "../../../types/locations.types"
import { complaintCommentsInitialValues, complaintCommentsSchema } from "Schema/Locations/locationSchema"
import { islocationComplaintsSaving, resolveLocationComplaint } from "../../../store/Location/locationComplaints.slice"
import useAppSelector from "../../../hooks/useAppSelector"

interface ResolveLocationComplaintModalProps {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedComplaint: LocationComplaintInterface | null
	onResolveCb?: () => void
	readonly?: boolean
}

const ResolveLocationComplaintModal: FC<ResolveLocationComplaintModalProps> = ({
	open,
	selectedComplaint,
	modalHandler,
	onResolveCb,
	readonly = false,
}) => {
	const { complaintComments, createComplaintComment, fetchComplaintCommentsByComplaint, isLoading, isSaving } =
		useComplaintComments()
	const dispatch = useAppDispatch()
	const isResolving = useAppSelector(islocationComplaintsSaving())

	const methods = useForm<CreateComplaintCommentDto>({
		resolver: yupResolver(complaintCommentsSchema),
		defaultValues: complaintCommentsInitialValues,
	})
	const { handleSubmit, reset } = methods

	const onResolveCbHandler = () => {
		modalHandler(false)
		onResolveCb && onResolveCb()
	}

	useEffect(() => {
		if (selectedComplaint && selectedComplaint._id) {
			fetchComplaintCommentsByComplaint(selectedComplaint._id)
		}
	}, [selectedComplaint])

	const onSubmit = (data: CreateComplaintCommentDto) => {
		if (selectedComplaint && selectedComplaint._id) {
			createComplaintComment({ comment: data.comment, complaint: selectedComplaint._id }, () => {
				reset()
			})
		}
	}

	const complaintResolutionHandler = (data: any) => {
		if (selectedComplaint && selectedComplaint._id)
			dispatch(resolveLocationComplaint(selectedComplaint._id, data, onResolveCbHandler))
	}

	const complaintDetailsData: { title: string; value?: string; className?: string }[] = useMemo(() => {
		return [
			{ title: "Complaint", value: selectedComplaint?.complaint },
			{
				title: "Date",
				value: getMomentZInstanceOfDate(selectedComplaint?.date).format("DD-MM-YYYY").split("T").toString(),
			},
			{
				title: "Reporting Officer",
				value:
					typeof selectedComplaint?.reportingOfficer === "object" &&
					selectedComplaint?.reportingOfficer !== null
						? `${selectedComplaint.reportingOfficer.firstName} ${selectedComplaint.reportingOfficer.lastName}`
						: selectedComplaint?.reportingOfficer || "N/A",
			},
			{
				title: "Officer",
				value:
					typeof selectedComplaint?.officer === "object" && selectedComplaint?.officer !== null
						? `${selectedComplaint.officer.firstName || "N/A"} ${
								selectedComplaint.officer.lastName || ""
						  }`.trim()
						: selectedComplaint?.officer || "N/A",
			},
			{ title: "Complainant", value: selectedComplaint?.complainant ?? "N/A" },
			{
				title: "Status",
				value: selectedComplaint?.status ? caseConverters.titleCase(selectedComplaint?.status) : "N/A",
			},
			...(selectedComplaint?.resolvedBy
				? [
						{
							title: "Resolved By",
							value: `${selectedComplaint.resolvedBy.firstName} ${selectedComplaint.resolvedBy.lastName}`,
						},
				  ]
				: []),
			...(selectedComplaint?.resolvedAt
				? [
						{
							title: "Resolved At",
							value: getMomentZInstanceOfDate(selectedComplaint?.resolvedAt)
								.format("DD-MM-YYYY")
								.split("T")
								.toString(),
						},
				  ]
				: []),
		]
	}, [selectedComplaint])

	const commentsTimeline = complaintComments.map(complaintComment => {
		return {
			children: (
				<div>
					<span className="text-[12px] font-normal">{complaintComment.comment}</span>

					<div className="flex ">
						<div className="flex items-center space-x-1">
							<Icon color="dark" name="user" size={10} />
							<div className="text-[10px] font-thin">
								{complaintComment.author.firstName} {complaintComment.author.lastName}
							</div>
						</div>

						<div className="ml-5 flex items-center space-x-1">
							<Icon color="dark" name="history" size={10} />
							<div className="text-[10px] font-thin">
								{getMomentZInstanceOfDate(complaintComment.createdAt).format(
									DATE_FORMATS.DD_MM_YYYY_HH_MM,
								)}
							</div>
						</div>
					</div>
				</div>
			),
		}
	})

	return (
		<Modal
			containerClass="w-[50%]"
			open={open}
			title={<div className="text-center text-md font-bold  tracking-wider text-dark">Handle complaint</div>}
			modalHandler={modalHandler}
		>
			<div className="flex h-full flex-col">
				<div className="h-full">
					<div className="flex w-[100%] flex-row">
						<div className="border-green flex-1 justify-center pl-5">
							{selectedComplaint && (
								<div className="mt-5 ml-4 ">
									<div className="mb-[0] text-[20px] font-bold">
										<div className="grid grid-cols-4 space-y-2">
											{complaintDetailsData.map(({ title, value, className = "col-span-1" }) => (
												<Statistic
													key={title}
													title={title}
													value={value || "N/A"}
													className={className}
													formatter={val => val}
												/>
											))}
										</div>

										<div>
											<span className="text-[12px] font-semibold text-stats-title">
												{" "}
												Description:{" "}
											</span>
											<div className="h-[100px] overflow-scroll text-3 font-normal scrollbar-thin">
												{selectedComplaint.details}
											</div>
										</div>

										<div className="mt-1">
											<div className="text-[12px] text-stats-title">Comments:</div>

											{isLoading ? (
												<Loading />
											) : (
												<div className="h-[200px] overflow-scroll pt-4 scrollbar-thin">
													<Timeline items={commentsTimeline} />
												</div>
											)}
										</div>

										{!readonly && (
											<FormProvider {...methods}>
												<form onSubmit={handleSubmit(onSubmit)}>
													<div className="mt-4 flex flex-row items-center justify-between">
														<FormInputAntd
															label="Leave a comment"
															type="text"
															name="comment"
															className="w-[97%]"
														/>
														<AntdButton
															htmlType="submit"
															className="mt-[18px]"
															disabled={isSaving}
															loading={isSaving}
														>
															Submit
														</AntdButton>
													</div>
												</form>
											</FormProvider>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				{selectedComplaint && !readonly && (
					<div className="mt-8 flex">
						<div className="flex h-fit w-full justify-end space-x-4">
							<AntdButton
								onClick={() => complaintResolutionHandler({ status: ComplaintState.RESOLVED })}
								loading={isResolving}
								disabled={isResolving}
							>
								Resolve
							</AntdButton>
						</div>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default ResolveLocationComplaintModal
