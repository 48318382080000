import { LocationComplaintInterface } from "@type/locations.types"
import axios from "axios"

const ROOT_PATH = "locations/complaints"

export class locationComplaintsService {
	static getLocationComplaints = (location: string) =>
		axios.get<LocationComplaintInterface[]>(`${ROOT_PATH}/${location}`)

	static saveLocationComplaint = (payload: any) => axios.post<LocationComplaintInterface>(ROOT_PATH, payload)

	static updateLocationComplaint = (id: string, payload: any) =>
		axios.put<LocationComplaintInterface>(`${ROOT_PATH}/${id}`, payload)

	static resolveLocationComplaint = (id: string, payload: any) =>
		axios.put<LocationComplaintInterface>(`${ROOT_PATH}/resolve/${id}`, payload)
}
